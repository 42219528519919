<template>
  <!-- Error page-->
  <div class="register-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo/>
    </b-link>

    <div class="locale-nav">
      <locale/>
    </div>

    <div class="mt-5 mt-md-3 py-3 px-1 py-md-3 px-md-2 py-lg-4 px-lg-4" v-if="!isShowLoading">
      <b-row>
        <b-col lg="8" offset-lg="2">

          <b-card>
            <h3 class="mb-2">{{ $t('user.personalInformation') }}</h3>

            <b-row>
              <b-col md="6" offset-md="3" class="text-center mb-3">
                <b-avatar size="200px" :src="userResult.userImage"/>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.idCard')">
                  <b-form-input v-model="userResult.userIdCard" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.prefix')">
                  <b-form-input v-model="userResult.prefixName" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.firstName')">
                  <b-form-input v-model="userResult.userFirstName" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.lastName')">
                  <b-form-input v-model="userResult.userLastName" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.position')">
                  <b-form-input v-model="userResult.positionName" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.tel')">
                  <b-form-input v-model="userResult.userTel" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('user.bloodType')">
                  <b-form-input v-model="userResult.userBloodTypeName" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('user.congenitalDisease')">
                  <b-form-input v-model="userResult.userCongenitalDisease" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.email')">
                  <b-form-input v-model="userResult.userEmail" plaintext/>
                </b-form-group>
              </b-col>
            </b-row>

            <h3 class="mb-2">{{ $t('user.emergencyContact') }}</h3>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('user.emergencyName')">
                  <b-form-input v-model="userResult.userEmergencyName" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.email')">
                  <b-form-input v-model="userResult.userEmail" plaintext/>
                </b-form-group>
              </b-col>
            </b-row>

            <h3 class="mb-2">{{ $t('user.headerTrainingInformation') }}</h3>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('user.trainingLatestDate')">
                  <b-form-input v-model="userResult.userLatestTrainingDate" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.trainingExpireDate')">
                  <b-form-input v-model="userResult.userLicenseEndDate" plaintext/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.trainingLatestScore')">
                  <b-form-input v-model="userResult.userLatestTrainingScore" plaintext/>
                </b-form-group>
              </b-col>
            </b-row>

            <h3 class="mb-2">{{ $t('user.attachments') }}</h3>

            <vue-good-table
                :columns="userAttach.columns"
                :rows="userAttach.rows"
                :pagination-options="{ enabled: false }"
            >
              <template
                  slot="table-row"
                  slot-scope="props"
              >
                <div v-if="props.column.field === 'userAttachmentImage'" class="text-center">
                  <thumbnail-file :src="props.row.userAttachmentImage"/>
                </div>

                <div v-else>{{ props.formattedRow[props.column.field] }}</div>
              </template>
            </vue-good-table>
          </b-card>

        </b-col>
      </b-row>
    </div>


  </div>
  <!-- / Error page-->
</template>

<script>
import {BLink, BButton, BImg, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormFile, BOverlay, BContainer, BAvatar} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Locale from "@core/layouts/components/app-navbar/components/Locale"
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import {UserService, UserAttachmentService} from "@/services"
import masterMixins from "@/mixins/masterMixins"

const userService = new UserService()
const userAttachmentService = new UserAttachmentService()

export default {
  components: {
    Locale,
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BSpinner,
    BOverlay,
    BContainer,
    BAvatar,
    VueGoodTable
  },
  mixins: [tableMixins, masterMixins],
  async created() {
    this.userId = this.$route.query.userId
    this.trainingId = this.$route.query.trainingId

    this.userAttach.columns = [
      {
        label: this.$t('user.attachmentFile'),
        field: 'userAttachmentImage',
        width: '30%',
        sortable: false
      }, {
        label: this.$t('user.attachmentType'),
        field: 'userAttachmentTypeName',
        width: '30%',
        sortable: false
      }, {
        label: this.$t('user.attachmentDesc'),
        field: 'userAttachmentDescription',
        width: '10%',
        sortable: false
      },
    ]
    await this.viewWorker(this.userId)

    this.isShowLoading = false
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    userId: '',
    trainingId: '',
    userAttach: {
      columns: [],
      rows: []
    },
    userResult: {
      userId: '',
      companyId: '',
      companyName: '',
      userStaffId: '',
      prefixId: '',
      prefixName: '',
      userFirstName: '',
      userLastName: '',
      userFullName: '',
      userIdCard: '',
      positionId: '',
      positionName: '',
      userCongenitalDisease: '',
      userTel: '',
      userImage: '',
      userImageRealName: '',
      userEmail: '',
      userEmergencyName: '',
      userEmergencyTel: '',
      userGroupId: '',
      userGroupName: '',
      userBlacklist: '',
      userBlacklistDesc: '',
      userStatus: '',
      userStatusName: '',
      userCategory: '',
      userLatestTrainingScore: '',
      userLatestTrainingDate: '',
      userLicenseEndDate: '',
      userTrainingIdNumber: '',
      userSectionManager: '',
      actionDate: '',
      actionBy: '',
    },
  }),
  methods: {
    async viewWorker(userId) {
      let {data: userResult} = await userService.getData(userId)
      let {data: userAttachResult} = await userAttachmentService.listData({userId: userId})

      if(userResult.totalRow === 0) {
        await this.$router.replace({name: 'publicDataNotFound'})
        return false
      }

      this.userResult = userResult.result
      // this.userResult.userBloodTypeName = this.showBloodType()

      this.userAttach.rows = userAttachResult.results
      if(this.trainingId !== this.userResult.userTrainingIdNumber) {
        await this.$router.replace({name: 'publicDataNotFound'})
      }
    },
    showBloodType() {
      let optionBloodTypes = this.listBloodType()
      let bloodResult = optionBloodTypes.find(data => data.bloodId === this.userResult.userBloodType)
      return bloodResult ? bloodResult.bloodName : ''
    },
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.register-wrapper {

  .register-inner {
    position: relative;
    max-width: 900px;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;

    .brand-text {
      font-weight: 600;
    }
  }

  .locale-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 2rem;
    margin: 0;

    ::marker {
      color: #fff;
    }
  }
}
</style>
